@import "colors";

.page-content {
	margin-bottom: 64px;
}

.main-page {
	.content-area {
		margin-top: 40px;

		@media screen and (min-width: 992px) {
			margin-top: 130px;
		}

		.col1 {
			padding-right: 0;
			padding-bottom: 24px;
			position: relative;

			@media screen and (min-width: 992px) {
				padding-right: 60px;
			}


			.back-bttn {
				position: absolute;
				top: -40px;

				@media screen and (min-width: 992px) {
					top: -85px;
				}

				button {
					padding-right: 0;
					padding-left: 0;
					border-radius: 0;

					svg {
						vertical-align: -10%;
					}
				}
			}
		}

		.col2 {
			padding-top: 0;

			@media screen and (min-width: 768px) {
				padding-top: 8px;
			}
		}

		h1 {
			@include accessible-line-height(1.2);
			font-style: normal;
			font-weight: 500;
			font-size: 3rem;
			letter-spacing: -0.015em;
			margin-bottom: 20px;

			@media screen and (min-width: 768px) {
				font-size: 4rem;
				margin-bottom: 26px;
			}

			@media screen and (min-width: 992px) {
				font-size: 6rem;
				margin-bottom: 32px;
			}
		}

		p {
			//font-family: 'Inter';
			font-style: normal;
			font-weight: 400;
			font-size: 1.8rem;
			letter-spacing: -0.015em;
			@include accessible-line-height(1.2);

			@media screen and (min-width: 768px) {
				font-size: 2.6rem;
			}

			@media screen and (min-width: 992px) {
				font-size: 3.0rem;
			}
		}

		.re-captcha-cont {
			text-align: center;
			> div {
				display: inline-block;
				> div {
					display: inline-block;
				}
			}
		}
	}
}

.theme-rara, .theme-mirko {
  .span-link {
    cursor: pointer;
  }


	.btn-auth-type {
		display: flex;
		justify-content: center;

		.btn {
			padding-right: 0;
			padding-left: 0;

			.landscape, .portrait {
				img, svg {
					max-height: 64px;
					max-width: 64px;
				}
			}

			.square {
				img, svg {
					max-height: 46px;
					max-width: 46px;
				}
			}

			.bigger {
				img, svg {
					max-height: 102px;
					max-width: 102px;
				}
			}

			@media screen and (min-width: 992px) {
				.landscape, .portrait {
					img, svg {
						max-height: 74px;
						max-width: 74px;
					}
				}

				.square {
					img, svg {
						max-height: 52px;
						max-width: 52px;
					}
				}

				.bigger {
					img, svg {
						max-height: 124px;
						max-width: 124px;
					}
				}
			}

			@media screen and (min-width: 1200px) {
				.landscape, .portrait {
					img, svg {
						max-height: 82px;
						max-width: 82px;
					}
				}

				.square {
					img, svg {
						max-height: 62px;
						max-width: 62px;
					}
				}

				.bigger {
					img, svg {
						max-height: 140px;
						max-width: 140px;
					}
				}
			}
		}
	}
}
